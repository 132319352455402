export default {
	name: 'header',
	component() {
		return {
			currentPanelIndex: null,
			isMobileNavOpen: false,
			isBannerHidden: false,
			lastScrollPosition: 0,

			init() {
				window._lenis.on('scroll', this.handleScroll.bind(this))
			},
			closeAllPanels() {
				this.currentPanelIndex = null
				document.body.classList.remove('desktop-panel-open')
			},
			handleLinkMouseEnter(e: MouseEvent) {
				const { target } = e

				if ((target as HTMLElement).getAttribute('aria-haspopup') !== 'true') {
					this.closeAllPanels()
					return
				}

				const { panelIndex } = (target as HTMLElement)?.dataset

				if (!panelIndex) {
					return
				}

				const index = parseInt(panelIndex, 10)
				this.currentPanelIndex = index

				document.body.classList.add('panel-open')
			},
			handleBurgerClick() {
				this.isMobileNavOpen = !this.isMobileNavOpen
				document.body.classList.toggle('panel-open')
			},
			closeMobileNav() {
				this.isMobileNavOpen = false
				document.body.classList.remove('panel-open')
			},
			handleScroll() {
				const scrollPosition = window.pageYOffset || document.documentElement.scrollTop
				const scrollDelta = scrollPosition - this.lastScrollPosition

				const scrollTolerance = 5

				// eslint-disable-next-line @shopify/binary-assignment-parens
				const isScrollingDown = scrollDelta > scrollTolerance
				// eslint-disable-next-line @shopify/binary-assignment-parens
				const isScrollingUp = scrollDelta < -scrollTolerance

				this.lastScrollPosition = scrollPosition

				if (isScrollingDown && !this.isBannerHidden) {
					this.isBannerHidden = true
					document.documentElement.classList.add('global-banner-hidden')
				} else if (isScrollingUp && this.isBannerHidden) {
					this.isBannerHidden = false
					document.documentElement.classList.remove('global-banner-hidden')
				}
			}
		}
	}
}
